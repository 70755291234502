@import '../../../../../layout/styles/helpers/helpers';

$input-background: #FFF !default;
$input-border: 1px solid lighten($gray, 10%);

.draggable-label-container {
  margin: (-$global-padding-smaller / 2);
  padding: $global-padding-smaller;
  border-radius: $global-padding;
  background-color: $gray-lighter;

  .draggable-label-item {
    flex: 0 1 calc(100% / 3 - 16px);
    max-width: 224px;
    min-width: 172px;
    margin: $global-padding-smaller / 2;
    border-radius: var(--global-radius, $global-radius);

    &__inactive {
      background-color: rgba(0, 0, 0, .1);

      & > * {
        visibility: hidden;
      }
    }
  }

  .draggable-label-item__active {
    cursor: move;
  }

  &__content {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
  }

  &__heading {
    margin: $global-padding-small $global-padding-smaller / 2;
    font-size: rem-calc(14);
    font-weight: $bold;
  }  
}

.draggable-label {

  &--used {

    & > * {
      height: 0;
      visibility: hidden;
    }
  }
}
