@import '../../../../layout/styles/helpers/helpers';

.input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: 0 $input-padding;
  line-height: 1;
  font-size: $input-font-size;
  border: 1px solid var(--input-border-color, $input-border-color);
  border-radius: $input-radius;
  background: $input-background;
  box-shadow: none;
  transition: border 0.2s, background-color 0.2s;

  &:hover {
    border-color: var(--input-border-color-hover, $input-border-color-hover);
  }

  &:focus {
    outline: 0;
    border-color: var(--input-border-color-focus, $input-border-color-focus);
  }

  &--large {
    height: $input-height-large;
  }

  &--small {
    height: $input-height-small;
  }

  &[type='file'] {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }

  &-file {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    min-height: 120px;

    &__drop-target {
      display: flex;
      flex: 1;
    }

    label {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: $global-padding-small $input-padding;
      border: 1px dashed var(--input-border-color, $input-border-color);
      border-radius: $input-radius;
      text-align: center;
      box-shadow: none;
      transition: border 0.2s, background-color 0.2s;

      &:hover {
        border-color: var(--input-border-color-hover, $input-border-color-hover);
      }

      &:focus {
        outline: 0;
        border-color: var(--input-border-color-focus, $input-border-color-focus);
      }

      .input-file__button {
        margin-left: $global-padding-smaller;
        cursor: pointer;
      }

      &.input-file--invalid {
        border-color: $color-warning;
      }
    }
  }
}

.input-error {
  margin: $global-padding-smaller 0;
}

.input-error-message {
  position: relative;
  display: inline-block;
  padding: ($global-padding-small / 2) $global-padding-small;
  color: var(--color-warning, $color-warning);
  font-size: 14px;
  font-weight: $bold;
  border-radius: var(--global-radius-smaller, $global-radius-smaller);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
    border-radius: var(--global-radius-smaller, $global-radius-smaller);
    background: var(--color-warning, $color-warning);
  }
}
