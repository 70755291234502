@import '../../styles/helpers/helpers';

$input-background: var(--white, $white);
$input-border: 1px solid transparent;

.rc-label {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 34px;
  margin: 0;

  &:focus {
    color: $input-color-focus;
    border: $input-border-focus;
    background-color: $input-background-focus;
  }

  .quill,
  &--readonly-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &--readonly {
    padding: 0 $global-padding-bigger 0 $global-padding-smaller;
    color: $input-color;
    line-height: normal;
    font-size: rem-calc(13);
    font-weight: normal;
    border-radius: var(--global-radius, $global-radius);
    border: 1px solid var(--input-border, $input-border);
    background-color: $input-background;

    [dir='rtl'] & {
      padding: 0 $global-padding-smaller 0 $global-padding-bigger;
    }

    &::after {
      position: absolute;
      top: 11px;
      right: 12px;
      width: 12px;
      height: 12px;
      content: '';
      background-image: $drag-handle;
      background-size: cover;

      [dir='rtl'] & {
        right: auto;
        left: 12px;
      }
    }

    p {
      margin: 0;
      line-height: 1.1;
    }
  }

  &--readonly.no-drag-handle {
    padding: 0 $global-padding-smaller;

    &::after {
      background-image: none;
    }
  }

  .ql-toolbar {
    visibility: hidden;
    text-align: center;
    padding: 5px 0;
    border-radius: var(--global-radius-smaller, $global-radius-smaller);
    color: black;
    background: var(--white, $white);

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 100%;

    .ql-formats {
      margin-right: 0px;

      button {
        height: 22px;
        padding: 2px;
        margin: 0px 3px;
      }
    }
  }

  &:hover .ql-toolbar {
    visibility: visible;
  }

  .ql-container {
    flex: 1 1 100%;
    min-height: 0;
    border: none;

    .ql-editor {
      display: flex;
      flex-direction: column;
      height: 34px;
      align-items: flex-start;
      justify-content: center;
      padding: 2px $global-padding-smaller;
      color: $input-color;
      line-height: normal;
      font-size: rem-calc(13);
      font-weight: normal;
      border-radius: var(--global-radius, $global-radius);
      border: 1px solid var(--input-border-color, $input-border-color);
      background-color: $input-background;
      transition: border 0.2s;

      &:focus {
        border-color: var(--color-info, $color-info);
      }
    }
  }

  .ql-clipboard {
    left: 0; // This is a hack required for draggable labels as the native HTML5 drag drop functionality
  } // causes the drag preview to be offet by this amount when it is set on the quill editor.

  &--invalid {
    height: auto;

    .ql-container {
      .ql-editor {
        border: 1px solid var(--color-warning, $color-warning);
      }
    }

    .input-error {
      margin-bottom: 0;
    }
  }
}
