@import '../../../../layout/styles/helpers/helpers';

.uploaded-image {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: $global-padding-small;
  text-align: center;
  background-color: $white;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 100%;
  }

  &--warning {
    border: 1px solid $color-warning;
  }

  &__controls {
    position: absolute;
    top: $global-padding-smaller;
    right: $global-padding-smaller;

    .btn.empty {
      width: 18px;
      height: 18px;
      background-color: var(--color-muted-text, $color-secondary);

      .icon {
        background-size: 60%;
        background-repeat: no-repeat;
        opacity: 1;
      }
    }
  }

  img {
    object-fit: contain;
    pointer-events: none;
  }
}
