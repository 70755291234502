@import '../../../layout/styles/helpers/helpers';

.click-select-item {
  
  & > * {
    border: 1px solid transparent;
    transition: border .2s;
  }

  &--selected {
    & > * {
      border: 1px solid var(--color-info, $color-info);
      transition: border .2s;
    }
  }
}

.click-select-drop {

  & > * {
    border: 1px solid transparent;
    transition: border .2s;
  }
}

.drop-target {

  > .click-select-drop {
    
    &--item-selected {

      &:hover {
      
        & > * {
          border: 1px solid var(--color-info, $color-info);
        }
      }
    }
  }
}
