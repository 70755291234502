@import '../styles/helpers/helpers';

.chart-d3 {
  $this: &;

  min-width: 60px;

  &,
  & > svg {
    max-width: 100%;
  }

  &__axis-line {
    stroke: lighten($gray-lighter, 50%);
  }

  &__axis-group {

    text {
      font-size: rem-calc(11);
      font-weight: $bold;
    }

    #{$this}__axis-line,
    #{$this}__tick .visx-line {
      stroke: lighten($gray, 10%);
    }
  }

  &__bar {
    fill: var(--color-info, $color-info);
    transition: all 1s .25s $easing;
    transition-property: height, y;
  }

  body &__tick {

    text {
      font-family: inherit;
      font-size: rem-calc(10);
      font-weight: normal;
      color: var(--color-muted-text, $color-secondary);
    }
  }

  &__background {
    fill: $white;
  }

  &__tooltip {
    position: absolute;
    padding: $global-padding-smaller $global-padding-small;
    border-radius: var(--global-radius-small, $global-radius-small);
    background-color: rgba(#FFF, .96);
    box-shadow: 0 6px 24px rgba($color-secondary, .3);
    font-size: rem-calc(11);

    @supports (backdrop-filter: blur(10px)) {
      border-radius: 0;
      background-color: rgba(#FFF, .9);
      backdrop-filter: blur(10px) saturate(200%);
    }
  }
}
