//
// variables
//

//
// sizing
$baseline: 12px;
$global-padding: $baseline * 1.5; // 18px
$global-padding-big: $baseline * 2; // 24px
$global-padding-bigger: $baseline * 3; // 36px
$global-padding-small: $baseline; // 12px 
$global-padding-smaller: $baseline * 2 / 3; // 8px

//
// colors

// Application Colors
$brand: #FB114B;
$blue: #0BACFE;
$green: limegreen;
$yellow: #F8C500;
$orange: #FF6C02;
$white: #FFF;

$color-mod-dark: 8%;
$color-mod-darker: 16%;
$color-mod-light: 8%;
$color-mod-lighter: 16%;

$color-primary: #FB114B;
$color-primary-dark: darken($color-primary, $color-mod-dark);
$color-primary-darker: darken($color-primary, $color-mod-darker);
$color-primary-light: lighten($color-primary, $color-mod-light);
$color-primary-lighter: lighten($color-primary, $color-mod-lighter);
$color-primary-hover: darken($color-primary, 10%) !default;

$color-info: #0BACFE;
$color-info-dark: darken($color-info, $color-mod-dark);
$color-info-darker: darken($color-info, $color-mod-darker);
$color-info-light: lighten($color-info, $color-mod-light);
$color-info-lighter: lighten($color-info, $color-mod-lighter);

$color-secondary: #818B96;
$color-secondary-dark: darken($color-secondary, $color-mod-dark);
$color-secondary-darker: darken($color-secondary, $color-mod-darker);
$color-secondary-light: lighten($color-secondary, $color-mod-light);
$color-secondary-lighter: lighten($color-secondary, $color-mod-lighter);

$color-success: $green;
$color-success-dark: darken($color-success, $color-mod-dark);
$color-success-darker: darken($color-success, $color-mod-darker);
$color-success-light: lighten($color-success, $color-mod-light);
$color-success-lighter: lighten($color-success, $color-mod-lighter);
$color-success-text: darken($color-success, 5%);

$color-error: $yellow;
$color-error-dark: darken($color-error, $color-mod-dark);
$color-error-darker: darken($color-error, $color-mod-darker);
$color-error-light: lighten($color-error, $color-mod-light);
$color-error-lighter: lighten($color-error, $color-mod-lighter);

$color-warning: $orange;
$color-warning-dark: darken($color-warning, $color-mod-dark);
$color-warning-darker: darken($color-warning, $color-mod-darker);
$color-warning-light: lighten($color-warning, $color-mod-light);
$color-warning-lighter: lighten($color-warning, $color-mod-lighter);

$dark-color: #283039;
$darker-color: #21272F;
$color-alert: $yellow !default;

$light-color: #F1F1F1;

// Scores
$score-high-color: $color-success;
$score-medium-color: $color-alert;
$score-low-color: $color-warning;

$gray: #B5BBC1 !default;
$gray-dark: darken($gray, 8) !default;
$gray-darker: darken($gray, 20) !default;
$gray-light: lighten($gray, 8) !default;
$gray-lighter: lighten($gray, 20) !default;

// global background for the app
$global-bg: #F3F2EF !default;
$global-bg-dark: #F3F2EF !default;

// borders
$border-white: 1px solid $white;
$border-dotted: 1px dotted #DDD;
// dark colours
$dark-color: #283039 !default; // century bg
$darker-color: #21272F;

//
// typography
$type-scale: (
  1: 12,
  2: 14,
  3: 16,
  4: 18,
  5: 20,
  6: 24,
) !default;

$leading-scale: (
  1: 16,
  2: 18,
  3: 20,
  4: 24,
  5: 30,
  6: 36,
) !default;

$font-size: 16px;
$font-size-body: rem-calc(14);
$font-size-lead: rem-calc(16);
$font-size-caption: rem-calc(12);
$font-size-title: rem-calc(24);
$font-size-subtitle: rem-calc(18);
$font-size-heading: rem-calc(20);
$font-size-subheading: rem-calc(16);

$font-family: 'proxima-soft', 'proxima-nova', 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font: $baseline / 1 $font-family;
$text-color: #333;
$text-color-muted: #666;
$text-color-light: #FFF;
$text-color-light-muted: #DDD;
$bold: 600;

$font-color-light-muted: lighten($color-secondary, 5%);

//
// buttons
$color-button-disabled: lighten($color-secondary, 20%);

$ui-size-tiny: 24px;
$ui-size-small: 30px;
$ui-size-medium: 36px;
$ui-size-large: 44px;

//
// inputs
$input-border-color: lighten($color-secondary, 20%);
$input-border-color-hover: $color-secondary;
$input-border-color-focus: $color-info;
$input-border-hover: 1px solid $input-border-color-hover !default;
$input-border-focus: 1px solid $input-border-color-focus !default;
$input-background: #FFF !default;
$input-background-hover: $input-background !default;
$input-background-focus: $input-background !default;
$input-color: #222 !default;
$input-color-hover: $input-color !default;
$input-color-focus: $input-color !default;
$input-font-size: 14px;
$input-height: $baseline * 3; // 36px
$input-height-large: $ui-size-large; // 44px
$input-height-small: $ui-size-small; // 30px
$input-padding: $global-padding-small;
$input-radius: 6px;

//
// other globals
$global-transition-duration: .2s;

// Various global styles
$body-background: #F3F2EF !default;
$body-font-color: #333 !default;

// We use these to make sure border radius matches unless we want it different.
$global-radius: 18px !default;
$global-radius-small: 12px !default;
$global-radius-smaller: 6px !default;
$global-radius-big: 24px !default;
$global-radius-bigger: 32px !default;
$global-rounded: 1000px !default;

// Z-index 
$z-index-behind: -1 !default;

// Flexbox margins and gutters
$gutter-width: $global-padding;
$outer-margin: $global-padding;
$gutter-compensation: -$gutter-width / 2;
$half-gutter-width: $gutter-width / 2;

// easings
$easing: cubic-bezier(.77, 0, .175, 1);

// Shadows
$shadow-level-1: 0 1px 4px -1px rgba(100, 100, 100, .2);
$shadow-level-2: 0 2px 8px -1px rgba(0, 0, 0, .2);
$shadow-level-3: 0 4px 12px -1px rgba(0, 0, 0, .2);

// Shadows for buttons
$shadow-button: 0 1px 2px -1px rgba(black, .1);

// Filter toolbar
$filter-toolbar-height: rem-calc(30);

// Subject colours

$subject-business-color: #26B5FE;

$subject-century-color: #EB0B67;

$subject-digital-color: #EEC913;

$subject-english-color: #F69C27;

$subject-french-color: #F14D54;
$subject-spanish-color: $subject-french-color;

$subject-geography-color: #258EFF;
$subject-history-color: $subject-geography-color;

$subject-mathematics-color: #B0D500;

$subject-science-color: #17ECC4;
$subject-biology-color: $subject-science-color;
$subject-multiplication-color: $subject-mathematics-color;
$subject-chemistry-color: $subject-science-color;
$subject-physics-color: $subject-science-color;

// Loading spinners

$fun-spinner: svg-uri('<?xml version="1.0" encoding="UTF-8"?><svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style>@keyframes spin {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}@keyframes spin-inverse {from {transform: rotate(0deg);}to {transform: rotate(-360deg);}}@keyframes stroke-middle {0% {stroke-dasharray: 60 60;}50% {stroke-dasharray: 10 180;}100% {stroke-dasharray: 60 60;}}@keyframes stroke-inner {0% {stroke-dasharray: 10 160;}50% {stroke-dasharray: 50 60;}100% {stroke-dasharray: 10 160;}}#inner,#middle,#outter {transform-origin: center center;animation: spin .9s linear infinite;}#inner {stroke-dasharray: 60 60;}#inner circle {animation: stroke-inner 2s linear infinite;}#middle {animation: spin-inverse 1.2s linear infinite;}#middle circle {animation: stroke-middle 2s linear infinite;}#outter {animation-duration: 1.5s;}#outter circle {stroke-dasharray: 80 250;}</style><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="spinner_2"><g id="inner"><rect id="sizer" x="0" y="0" width="80" height="80"></rect><circle stroke="#{$color-primary}" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" cx="40" cy="40" r="17"></circle></g><g id="middle"><rect id="sizer" x="0" y="0" width="80" height="80"></rect><circle stroke="#{$color-secondary}" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" cx="40" cy="40" r="27"></circle></g><g id="outter"><rect id="sizer" x="0" y="0" width="80" height="80"></rect><circle stroke="#{$color-info}" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" cx="40" cy="40" r="37"></circle></g></g></g></svg>');
