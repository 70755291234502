@import '../../../../layout/styles/helpers/helpers';

.alternative-board-matching {
  position: relative;

  &__box {
    @include box;
    padding: $global-padding-small $global-padding $global-padding;
    background-color: var(--white, $white);
    margin-bottom: $global-padding;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    flex: 1;
    padding-top: $global-padding-smaller;
    font-size: rem-calc(20);
    font-weight: $bold;
  }

  &__controls {
    margin-right: -($global-padding-small / 2);
  }

  &__pairs {
    margin-bottom: $global-padding-smaller;
    text-align: center;

    &--image {
      .prompt-answer-list {
        max-width: 560px;
      }
    }
  }

  &__additional {
    margin-bottom: $global-padding-small;
  }

  &__correct-feedback,
  &__incorrect-feedback {
    margin-top: $global-padding;

    .form-label {
      margin: ($global-padding-small / 2) 0;
    }

    .quill-small {
      .ql-container.ql-snow.ql-disabled {
        .ql-editor {
          padding: 0 0 $global-padding-smaller;
        }
      }
    }
  }

  &__feedback {
    margin-top: $global-padding;
    padding: $global-padding-small;
    border-radius: $global-padding;
    background-color: $gray-lighter;
  }

  &__reveals {
    margin-top: $global-padding;
    padding: $global-padding-small;
    border-radius: $global-padding;
    background-color: rgba($color-info, 0.1);
  }

  &__reveals-header {
    margin: $global-padding-small 0;
    font-size: rem-calc(14);
    font-weight: $bold;
    color: darken($color-info, 5%);
  }

  &__footer-controls {
    display: flex;
    justify-content: flex-end;
    margin: $global-padding-big 0 $global-padding;

    .idk {
      margin-right: auto;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  &__learn {
    position: relative;

    .alternative-board-matching {
      &__question {
        label {
          display: none;
        }
      }

      &__feedback {
        .quill-small {
          .ql-container.ql-snow {
            background-color: transparent;

            &.ql-disabled .ql-editor {
              padding: $global-padding-smaller 0;
            }
          }
        }
      }

      &__pairs {
        &--image {
          .prompt-answer-pair__field--image {
            max-width: 224px;
          }

          .uploaded-img img {
            max-height: 224px;
          }
        }
      }
    }

    .prompt-answer-list {
      &__headings {
        display: none;
      }
    }
  }

  // styling for question when displayed within QLA
  &__body {

    .cds-qla-level-2__content & {
      margin: $global-padding-smaller auto $global-padding;
      padding: $global-padding;
      border: 1px solid $gray-light;
      border-radius: var(--global-radius, $global-radius);
      background: $white;
    }
  }
}
