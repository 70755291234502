//
// mixins
//

@mixin text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Box
@mixin box($radius: var(--global-radius, $global-radius)) {
  border-radius: $radius;
  background: $white;
  box-shadow: 0 1px 3px -1px rgba(black, 0.2);
}

// Card Hover Behaviour
@mixin card($radius: var(--global-radius, $global-radius)) {
  overflow: hidden;
  border-radius: $radius;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  transform-origin: center center;
  transition: box-shadow 0.3s $easing, transform 0.3s $easing;
  will-change: box-shadow, transform;

  &:hover {
    box-shadow: 0 10px 18px -8px rgba(0, 0, 0, 0.35);
    transform: translateY(-4px);
  }

  &:active {
    box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.3);
    transform: translateY(-1px);
    transition-duration: 0.1s;
  }
}

// Small Upper Case Labels used throughout the app
@mixin label {
  color: var(--color-muted-text, $color-secondary);
  font-size: rem-calc(11);
  font-weight: normal;
  text-transform: uppercase;
}

// score colors

@mixin data-score {
  &[data-score^='1'],
  &[data-score^='2'],
  &[data-score^='3'] {
    color: var(--color-score-low, $color-warning);
  }

  &[data-score^='4'],
  &[data-score^='5'],
  &[data-score^='6'] {
    color: var(--color-score-medium, $color-alert);
  }

  &[data-score^='7'],
  &[data-score^='8'],
  &[data-score^='9'],
  &[data-score='100'] {
    color: var(--color-score-high, $color-success);
  }

  &[data-score='0'],
  &[data-score='1'],
  &[data-score='2'],
  &[data-score='3'],
  &[data-score='4'],
  &[data-score='5'],
  &[data-score='6'],
  &[data-score='7'],
  &[data-score='8'],
  &[data-score='9'] {
    color: var(--color-score-low, $color-warning);
  }
}

/// Background, Hover and Active variations for background-color
@mixin bg-hover($color: $color-primary) {
  background-color: $color;

  &:hover {
    background-color: darken($color, 10%);
  }

  &:active {
    background-color: darken($color, 20%);
  }
}

@mixin line-clamp($lines: 3) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin hide-text {
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

//
// Dropdown tip mixins
//

// For dropdown bottom left aligned
@mixin drop-left($background-color: white, $border-radius: var(--global-radius-small, $global-radius-small)) {
  border-radius: $border-radius;
  background-color: $background-color;
  transform-origin: -$global-padding calc(100% - #{$border-radius} + calc(26 / 2));

  &::before {
    @include dropdown-tip-left($color: white);

    content: '';
    width: 11px;
    height: 26px;
    position: absolute;
    bottom: $border-radius;
    right: auto;
    left: -10px;
    background-position: left center;
    background-repeat: no-repeat;
  }
}

// For dropdown bottom right aligned
@mixin drop-right($background-color: white, $border-radius: var(--global-radius-small, $global-radius-small)) {
  border-radius: $border-radius;
  background-color: $background-color;
  transform-origin: -$global-padding calc(100% - #{$border-radius} + calc(26 / 2));

  &::before {
    @include dropdown-tip-right($color: white);

    content: '';
    width: 11px;
    height: 26px;
    position: absolute;
    bottom: $border-radius;
    right: -10px;
    left: auto;
    background-position: right center;
    background-repeat: no-repeat;
  }
}

// Dropdown tip mixins

@mixin dropdown-tip-left($color: white) {
  background-image: svg-uri(
    '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="26" viewBox="0 0 10 26"><path fill="#{$color}" fill-rule="evenodd" d="M-8.8817842e-16,13 C8.8817842e-16,17.5 10,19 10,26 L10,4.26325641e-14 C10,7 -2.3869795e-15,8.5 -8.8817842e-16,13 Z"/></svg>'
  );
}

@mixin dropdown-tip-right($color: white) {
  background-image: svg-uri(
    '<svg width="10px" height="26px" viewBox="0 0 10 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="account-menu-tip" transform="translate(5.000000, 13.000000) rotate(-180.000000) translate(-5.000000, -13.000000) translate(-0.000000, -0.000000)" fill="#{$color}"><path d="M-8.8817842e-16,13 C-8.8817842e-16,17.5 10,19 10,26 L10,4.26325641e-14 C10,7 -8.8817842e-16,8.5 -8.8817842e-16,13 Z" id="Path"></path></g></g></svg>'
  );
}

// CSS triangles
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;

  @if $triangle-direction == top {
    border-color: $triangle-color transparent transparent;
    border-top-style: solid;
  }

  @if $triangle-direction == bottom {
    border-color: transparent transparent $triangle-color;
    border-bottom-style: solid;
  }

  @if $triangle-direction == left {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }

  @if $triangle-direction == right {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// Disable OS-level styles
@mixin no-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
}

// Mixins for boards

@mixin board($radius: var(--global-radius, $global-radius)) {
  position: relative;
  margin-top: $global-padding;
  padding: $global-padding-small;
  border: 1px solid rgba(black, 0.02);
  border-radius: $radius;
  background: $global-bg;
}

// Mixin for table column border

@mixin table-header-column-shadow {
  &::before {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: -1px;
    left: auto;
    width: 2px;
    content: '';
    background: linear-gradient(90deg, rgba(black, 0.1), rgba(black, 0)) 0 0 / 100% no-repeat;
  }
}

// Mixin for a centered pseudo element.
@mixin pseudo($width, $height) {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -$height / 2;
  margin-left: -$width / 2;
  width: $width;
  height: $height;
}

// Mixin to mitigate blurry jank on animated elements.
@mixin will-animate($animated-props...) {
  $animated-props: transform, opacity, color, background-color, position !default;
  will-change: $animated-props;
  backface-visibility: hidden;
  transform: translateZ(0);
}

/// Mixin for type scale.
/// @param {String} $key - Number in scale
/// @param {String} $type-scale - Map of font-sizes
/// @param {String} $leading-scale - Map of line-heights
@mixin typography($key: 1, $type-scale: $type-scale, $leading-scale: $leading-scale) {
  font-size: rem-calc(map-get($type-scale, $key));
  line-height: rem-calc(map-get($leading-scale, $key));
}
