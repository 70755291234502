@import '../../../../layout/styles/helpers/helpers';

.alternative-board-submitted {
  &__box {
    @include box;

    margin-bottom: $global-padding;
    padding: $global-padding-small $global-padding $global-padding;
    background-color: var(--white, $white);
  }

  &__submitted-pairs,
  &__options {
    margin-bottom: $global-padding;
  }

  &__options {
    .draggable-label-item__inactive {
      background-color: rgba(0, 0, 0, 0.1);

      & > * {
        visibility: hidden;
      }
    }
  }

  &__feedback {
    margin: $global-padding 0;
    padding: $global-padding-smaller;
    border-radius: $global-padding;
    background-color: $light-color;

    .quill-small {
      .ql-container.ql-snow {
        background-color: transparent;

        &.ql-disabled .ql-editor {
          padding: $global-padding-smaller 0;
        }
      }
    }
  }

  &__reveals {
    margin-top: $global-padding;
    padding: $global-padding-small;
    border-radius: $global-padding;
    background-color: rgba($color-info, 0.1);
  }

  &__reveals-header {
    margin: $global-padding-small 0;
    font-size: rem-calc(14);
    font-weight: $bold;
    color: darken($color-info, 5%);
  }

  &__result {
    display: block;
    margin: 0.5em 0 0;
    font-weight: $bold;
    text-transform: capitalize;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin: $global-padding-big 0 $global-padding;

    .idk {
      margin-right: auto;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  // styling for question when displayed within QLA
  .cds-qla-level-2__content & {
    margin: $global-padding-smaller auto $global-padding;
    padding: $global-padding;
    border: 1px solid $gray-light;
    border-radius: var(--global-radius, $global-radius);
    background: $white;
  }

  .cds-qla-level-2__content &__options {
    margin-bottom: $global-padding-small;
  }

  .cds-qla-level-2__content &__controls {
    display: none;
  }
}
