@import '../../../../layout/styles/helpers/helpers';

$after-element-height: 18px;
$after-element-width: 18px;
$after-element-margin: 12px;

.prompt-answer-pair {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: $global-padding-small;

  &__container {
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: $global-padding-small;
    border: 1px solid transparent;
    border-radius: $global-padding-small;
    background-color: $gray-lighter;
    background-image: $arrow-right-grey;
    background-position: center center;
    background-repeat: no-repeat;

    [dir='rtl'] & {
      background-image: $arrow-left-grey;
    }

    &:focus-within {
      background-image: $arrow-right-blue;

      [dir='rtl'] & {
        background-image: $arrow-left-blue;
      }
    }
  }

  &__field {
    display: flex;
    flex: 1 1 auto;
    max-width: 47%;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    &--text {
      &:first-of-type {
        .matching-answer-draggable {
          background-color: transparent;
        }
      }

      .matching-answer-draggable {
        border: 1px solid transparent;
      }
    }

    &--image {
      min-width: 172px;

      .input-file {
        label {
          &::after {
            content: '';
            height: 0;
            padding-bottom: 100%;
          }
        }
      }

      .uploaded-image img {
        width: auto;
        height: auto;
        max-height: 250px;
      }

      .no-image-placeholder {
        position: relative;
        display: flex;
        border-radius: $global-padding-small;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          height: 0;
          padding-bottom: 100%;
        }
      }
    }
  }

  // Result states
  &--submitted {
    &::after {
      width: $after-element-width;
      top: calc(50% - #{$after-element-height}/ 2);
      height: $after-element-height;
      right: $after-element-margin;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      margin-left: $after-element-margin;

      [dir='rtl'] & {
        right: auto;
        left: $after-element-margin;
        margin-right: $after-element-margin;
        margin-left: 0;
      }
    }

    .rc-label {
      background-color: transparent;
    }
  }

  &--correct {
    &::after {
      background-image: $icon-action-correct;
      background-color: $color-success;
      background-size: 88%;
      content: '';
    }

    .prompt-answer-pair__container {
      border: 1px solid $color-success;
      background-image: $arrow-right-green;
      background-color: rgba($color: $color-success, $alpha: 0.05);
      // offset by margin either side of pseudo element plus width.
      width: calc(100% - (#{$after-element-margin} + #{$after-element-margin} + #{$after-element-width}));

      [dir='rtl'] & {
        background-image: $arrow-left-green;
      }

      .matching-answer-draggable {
        background-color: transparent;
      }
    }
  }

  &--incorrect {
    .prompt-answer-pair__container {
      border: 1px solid $color-warning;
      background-image: $arrow-right-orange;
      background-color: rgba($color: $color-warning, $alpha: 0.05);
      // offset by margin either side of pseudo element plus width.
      width: calc(100% - (#{$after-element-margin} + #{$after-element-margin} + #{$after-element-width}));

      [dir='rtl'] & {
        background-image: $arrow-left-orange;
      }

      .matching-answer-draggable {
        background-color: transparent;
      }
    }

    &::after {
      background-image: $icon-action-close;
      background-size: 66%;
      background-color: $color-warning;
      content: '';
    }
  }

  &--reveal {
    &::after {
      background-image: $icon-action-correct;
      background-color: $blue;
      background-size: 88%;
      content: '';
    }

    .prompt-answer-pair__container {
      border: 1px solid $blue;
      background-color: rgba($color: $blue, $alpha: 0.1);
      // offset by margin either side of pseudo element plus width.
      width: calc(100% - (#{$after-element-margin} + #{$after-element-margin} + #{$after-element-width}));

      .matching-answer-draggable {
        background-color: transparent;
      }
    }
  }

  &--hover {
    .prompt-answer-pair__container {
      border: 1px solid var(--color-info);
    }
  }
}
