@import '../../../../layout/styles/helpers/helpers';

.label-pair-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: (-$global-padding-smaller / 2);
  padding-bottom: $global-padding-smaller / 2;

  &__item {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 1 calc(100% / 3 - 8px);
    min-width: 172px;
    margin: $global-padding-smaller / 2;
  }
}
