@import '../../../../layout/styles/helpers/helpers';

// $input-background: #FFF !default;
// $input-border: 1px solid lighten($gray, 10%);

.label-pair {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: $global-padding-smaller;
  border-radius: $global-padding;
  background-color: $gray-lighter;

  &__label {
    margin-top: $global-padding-smaller;
  }

  &__image {
    .uploaded-image img {
      width: auto;
      height: auto;
      max-height: 250px;
    }

    .input-file {
      label {
        &::after {
          content: '';
          height: 0;
          padding-bottom: 100%;
        }
      }
    }
  }

  &--viewing {
    border: 1px solid transparent;

    &.label-pair--hover {
      border: 1px solid var(--color-info);
    }
  }

  // Result states

  &--submitted {
    .rc-label {
      background-color: transparent;

      &::after {
        width: 18px;
        height: 18px;
        top: 4px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &--correct {
    border: 1px solid $color-success;
    background-color: rgba($color: $color-success, $alpha: 0.05);

    .rc-label::after {
      background-image: $icon-action-correct;
      background-color: $color-success;
    }
  }

  &--reveal {
    border: 1px solid $blue;
    background-color: rgba($color: $blue, $alpha: 0.1);

    .rc-label::after {
      background-image: $icon-action-correct;
      background-color: $blue;
    }
  }

  &--incorrect {
    border: 1px solid $color-warning;
    background-color: rgba($color: $color-warning, $alpha: 0.05);

    .rc-label::after {
      background-image: $icon-action-close;
      background-size: 66%;
      background-color: $color-warning;
    }
  }
}

.sortable-ghost {
  opacity: 0.2;
}
