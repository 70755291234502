@import '../../../../layout/styles/helpers/helpers';

.matching-additional-list {
  padding: $global-padding-small;
  border-radius: $global-padding;
  background-color: $gray-lighter;

  &__item-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: (-$global-padding-smaller / 2);
    padding-bottom: $global-padding-smaller / 2;
  }

  &__heading {
    margin: $global-padding-small 0;
    font-size: rem-calc(14);
    font-weight: $bold;
  }

  &__item {
    flex: 1 0 48%;
    max-width: calc(100% / 2 - 12px);
    margin-bottom: $global-padding-small;

    &:nth-child(odd) {
      margin-right: $global-padding-small;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: $global-padding-small;
      }
    }
  }

  &--image {
    .matching-additional-list__item {
      flex: 0 1 calc(100% / 3 - 16px);
      min-width: 170px;
      margin: ($global-padding-smaller);
    }
  }
}
