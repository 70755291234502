@import '../../styles/helpers/helpers';

.matching-answer-draggable {
  display: flex;
  flex-flow: row nowrap;
  height: auto;
  align-items: center;
  padding: 0 $global-padding;
  border-radius: $input-radius;
  background-color: var(--white, $white);

  &__content {
    display: flex;
    flex: 1 1 auto;
    height: auto;
    min-height: 32px;
    align-items: center;
    color: $input-color;
    line-height: normal;
    font-size: rem-calc(13);
    font-weight: normal;

    p:nth-last-child(1):first-child {
      margin-bottom: 0;
    }

    // Override - text that has background color set to white.
    span {
      background-color: inherit !important; // sass-lint:disable-line no-important
    }

    strong {
      background-color: inherit !important; // sass-lint:disable-line no-important
    }
  }

  &__handle {
    width: 40px;
    height: 40px;
    background: $drag-handle no-repeat center right;
    cursor: move;

    [dir='rtl'] & {
      background-position: center left;
    }
  }
}
