@import '../../layout/styles/helpers/helpers';

.question-type-feedback {
  display: block;
  margin: 0;
  color: var(--color-muted-text, $color-secondary);
  font-size: inherit;
  font-weight: $bold;

  & + p {
    margin: 0 0 .5em;
  }
}
