// MathQuill does not have a class on the keyboard for formulas.
// This means we have to the flex-wrap via a less pretty method...
// Use Z-index to bring forward over background elements
.ql-tooltip.ql-editing {
  z-index: 10;

  div:last-of-type {
    flex-wrap: wrap;
  }

}

// MathQuill requires overriding to stop formula box disappering off side of page.
div.ql-tooltip.ql-editing {
  left: unset !important; // sass-lint:disable-line no-important
}

// Give enough height for fractions to be created
// and not have the separating line be lost
.ql-editor > p {
  line-height: 1.6;
}
