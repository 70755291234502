@import '../styles/helpers/helpers';

.scatter-graph-d3 {

  .chart-d3 {

    &__tick {
      display: none;

      &:first-of-type,
      &:last-of-type {
        display: initial;
      }

      & > svg > text {
        display: none;
      }
    }
  }

  &__average-line {
    stroke: var(--color-info, $color-info);
    stroke-width: 1;
    stroke-dasharray: 3 6;
    stroke-linecap: round;
  }

  &__average-text {
    font-size: rem-calc(11);
  }

  &__intervention {
    font-size: rem-calc(18);
    font-weight: $bold;

    &--is-widget {
      font-size: rem-calc(11);
    }

    &--top-left {
      fill: var(--color-info, $color-info);
    }

    &--bottom-left {
      fill: #EDB000;
    }

    &--bottom-right {
      fill: var(--color-warning, $color-warning);
    }

    &--top-right {
      fill: var(--color-success, $color-success);
    }
  }

  &__point {
    transition: stroke-width .3s $easing;
    stroke-width: 0;

    &:hover {
      stroke-width: 4;
    }
  }
}
