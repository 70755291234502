@import '../../../layout/styles/helpers/helpers';

$mobile-button-height: $global-padding-big * 2;

.exact-answer {
  $this: &;

  overflow: hidden;
  flex: 1 1;

  h4,
  h5,
  p {
    margin: 0.66em 0;
    padding: 0;
    line-height: 1.4;
    font-weight: normal;
  }

  .question-content-equation {
    margin: 0.66em 0;
  }

  &__question {
    .katex {
      font-size: rem-calc(24);
    }

    .katex-display {
      margin: 0;
    }
  }

  &__optionslist {
    margin: 1em 0;
    padding: 0;
    list-style: none;
    font-size: inherit;
  }

  &__answer {
    $answer-size: 30 / 16 * 1em;
    $answer-border-width: 2 / 16 * 1em;
    $answer-border-color: $gray;
    $answer-answered-color: $color-info;
    $answer-correct-color: $color-success;
    $answer-incorrect-color: $color-warning;

    position: relative;
    display: flex;
    align-items: center;
    margin: 6px (-0.5em);
    padding: 6px $global-padding-smaller 6px 3.125em;
    min-height: $answer-size + 1em;

    &::before {
      content: '';
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      width: $answer-size;
      height: $answer-size;
      border-radius: 50%;
      border: $answer-border-width solid $answer-border-color;
      background: center center no-repeat;
    }

    &--correct::before {
      background-color: $answer-correct-color;
      background-image: $assessment-correct;
      background-position: center center;
      background-size: 58%;
      border-color: transparent;
    }

    &--incorrect::before {
      background-color: $answer-incorrect-color;
      background-image: $assessment-incorrect;
      background-size: 57%;
      border-color: transparent;
    }
  }

  &--qla {
    flex: 0 1 40em;
    margin: $global-padding-smaller auto $global-padding;
    padding: $global-padding;
    font-size: rem-calc(13);
    border-radius: var(--global-radius, $global-radius);
    background: $white;
    border: 1px solid $gray-light;

    #{$this}__exact-answer {
      padding: $global-padding-smaller $global-padding-small;
    }
  }

  &--equation {
    .input-equation {
      margin: 0;
    }
  }

  &__response {
    margin: 1em 0;
    padding: 1em;
    line-height: 1.2;
    border-radius: var(--global-radius-small, $global-radius-small);
    background-color: $light-color;
  }

  &__response h5,
  &__explanation {
    display: block;
    margin: 0 0 0.5em;
    color: var(--color-muted-text, $color-secondary);
    font-size: inherit;
    font-weight: $bold;
  }

  &__exact-answer {
    $input-border: 1px solid $input-border-color;

    min-width: rem-calc(100);
    border: $input-border;
    border-radius: $input-radius;
    background: $input-background;
    color: $input-color;
    width: 100%;
    margin: $global-padding 0;
    padding: $global-padding-smaller $global-padding-small;

    &--correct {
      border-color: var(--color-success-text, $color-success);
      background-color: rgba($color-success, 0.05);
    }

    &--incorrect {
      border-color: var(--color-warning-text, $color-warning);
      background-color: rgba($color-warning, 0.05);
    }

    &--skipped {
      height: rem-calc(40);
    }
    &--answered-no-result {
      height: initial;
    }

    &--unanswered,
    &--loading {
      border: none;
      padding: 0;
    }
  }

  &--equation &__exact-answer {
    &--loading {
      border: 1px solid var(--color-info, $color-info);
      padding: $global-padding-smaller $global-padding-small;
    }

    // Format submitted answer to match equation input
    .katex-display {
      margin: 0;
      text-align: initial;

      & > .katex {
        text-align: initial;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin: $global-padding-big 0 0;
    flex: 1;

    // stack controls vertically and stretch full-width for mobile portrait
    @media (max-width: 420px) and (orientation: portrait) {
      flex-direction: column;

      .btn {
        width: 100%;
        height: $mobile-button-height;
        border-radius: $mobile-button-height;

        & + .btn {
          margin: $global-padding-small 0 0;
        }
      }
    }

    .idk {
      margin-right: auto;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  &__reveals {
    margin: 1em 0;
    padding: 1em;
    border-radius: var(--global-radius-small, $global-radius-small);
    background-color: rgba($color-info, 0.1);
  }

  &__reveals-header {
    margin: 0 0 0.5em;
    font-size: inherit;
    font-weight: $bold;
    color: darken($color-info, 5%);
  }
}
