@import '../../../../layout/styles/helpers/helpers';

.image-area {
  &__spinner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
