@import '../../layout/styles/helpers/helpers';

.drop-target {
  width: 100%; 
  height: 100%;

  .no-image-placeholder {
    position: relative;
    display: flex;
    border-radius: $global-padding-small;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      height: 0;
      padding-bottom: 100%;
    }
  }
}
