@import '../../styles/helpers/helpers';

.progress {
  display: flex;
  flex-direction: row;
  max-width: 600px;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;

  // dotted line spans container but clipped at the edges to line up with first/last steps
  &::before {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: .5;
    border-top: 1px solid $color-secondary-lighter;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    position: relative;
    font-size: rem-calc(12);
    font-weight: $bold;
    text-align: center;

    &::before {
      display: block;
      width: 16px;
      height: 16px;
      margin: 5px;
      content: '';
      border-radius: 50%;
      box-shadow: 0 0 1px 5px $global-bg;
    }

    &:first-of-type {

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 24px;
        background: $global-bg;
        z-index: -1;
      }
    }

    &:last-of-type {

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 24px;
        background: $global-bg;
        z-index: -1;
      }
    }


    & > div {
      margin-top: 6px;
    }

    &.completed,
    &.current {
      color: var(--color-muted-text, $color-secondary);

      &::before {
        background-color: $color-info;
      }
    }

    &.current {
      color: $color-info;

      &::before {
        box-shadow: 0 0 0 5px lighten($color-info, 28%), 0 0 0 9px lighten($color-info, 40%);
      }
    }

    &.future {
      color: lighten($color-secondary-light, 15%);

      &::before {
        background-color: lighten($color-secondary-light, 15%);
      }
    }
  }
}
