@import '../../../layout/styles/helpers/helpers';

$study-session-radio-answered-color: var(--study-session-color-selected, $color-info);
$mobile-button-height: $global-padding-big * 2;

.multi-question {
  $this: &;

  flex: 1 1;

  h4,
  h5,
  p {
    margin: .66em 0;
    padding: 0;
    line-height: 1.4;
  }

  &__question {
    .katex {
      font-size: rem-calc(24);
    }
  }

  .question-content-equation {
    margin: 0.66em 0;

    .katex {
      font-size: rem-calc(24);
    }

    .katex-display {
      margin: 0;
    }
  }

  &--qla {
    flex: 0 1 40em;
    margin: $global-padding-smaller auto $global-padding;
    padding: $global-padding;
    font-size: rem-calc(13);
    border-radius: var(--global-radius, $global-radius);
    background: $white;
    border: 1px solid $gray-light;
  }

  &__options {
    .rc-media-wrapper {
      margin: 0;
      display: block;

      p {
        margin: 0;

        & + p {
          margin-top: 0.66em;
        }
      }

      img {
        margin: 0;
      }

      .question-content-equation {
        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__optionslist {
    margin: 1em 0;
    padding: 0;
    list-style: none;
    font-size: inherit;
  }

  &__answer {
    $answer-size: 30 / 16 * 1em;
    $answer-border-width: 2 / 16 * 1em;
    $answer-border-color: $gray;
    $answer-answered-color: $color-info;
    $answer-correct-color: var(--color-success, $color-success);
    $answer-incorrect-color: var(--color-warning, $color-warning);

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 6px (-0.5em);
    padding: 6px $global-padding-smaller 6px 3.125em;
    min-height: $answer-size + 1em;
    border-radius: var(--global-radius-small, $global-radius-small);
    transition: background-color 0.2s;
    font-size: inherit;

    @media (max-width: 420px) and (orientation: portrait) {
      margin: ($global-padding / 2) (-.5em);
    }

    @media (max-width: 896px) {
      border: 1px solid transparent;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: .5em;
      left: .5em;
      width: $answer-size;
      height: $answer-size;
      border-radius: 50%;
    }

    &::before {
      border: $answer-border-width solid $answer-border-color;
      transition: border-color .2s ease;
    }

    &::after {
      background: center center no-repeat;
      background-size: 0;
      transition: background-color .3s ease, opacity .3s ease, transform .3s ease;
      transform: scale(0);
      opacity: 0;
    }

    &--correct::after {
      background-color: $answer-correct-color;
      background-image: $assessment-correct;
      background-position: center center;
      background-size: 16px;
      opacity: 1;
      transform: scale(1);
    }

    &--incorrect::after {
      background-color: $answer-incorrect-color;
      background-image: $assessment-incorrect;
      background-size: 16px;
      opacity: 1;
      transform: scale(1);
    }

    &--selected {
      &::before {
        border-color: transparent;
      }

      &::after {
        background-size: 57%;
        transform: scale(1);
        background-color: $study-session-radio-answered-color;
        opacity: 1;
      }
    }

    &--unanswered {
      cursor: pointer;

      @media (max-width: 896px) {
        border-color: rgba($color-secondary, .1);
      }

      &:hover {
        background-color: rgba($color-secondary, .1);

        &::before {
          border-color: $study-session-radio-answered-color;
        }
      }
    }

    #{$this}__input {
      @include no-appearance;

      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }
  }

  &__response {
    margin: 1em 0;
    padding: 1em;
    line-height: 1.2;
    border-radius: var(--global-radius-small, $global-radius-small);
    background-color: $light-color;

    h5,
    .question-type-feedback {
      display: block;
      margin: 0 0 0.5em;
      color: var(--color-secondary-dark, $color-secondary-dark);
      font-size: inherit;
      font-weight: $bold;

      &:last-child {
        margin: 0;
      }
    }

    p {
      margin: 0;

      & p {
        margin-top: 0.5em;
      }
    }
  }

  &__exact-answer {
    $input-border: 1px solid $input-border-color;

    display: inline-block;
    min-width: rem-calc(100);
    margin: 0 0 $global-padding;
    padding: $global-padding-smaller $global-padding-small;
    border: $input-border;
    border-radius: var(--input-radius, $input-radius);
    background: $input-background;
    color: $input-color;

    &--correct {
      border-color: var(--color-success-text, $color-success);
      background-color: rgba($color-success, .05);
    }

    &--incorrect {
      border-color: var(--color-warning-text, $color-warning);
      background-color: rgba($color-warning, .05);
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin: $global-padding-big 0 $global-padding;

    @media (max-width: 420px) and (orientation: portrait) {
      flex-flow: column nowrap;
      align-items: stretch;
      margin: $global-padding-smaller 0 0;

      .btn {
        width: 100%;
        height: $mobile-button-height;
        border-radius: $global-padding-big;

        & + .btn {
          margin: $global-padding-small 0 0;
        }
      }
    }

    .idk {
      margin-right: auto;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

// fixes size and weight inheritence issues
.multi-question,
.multi-question__explanation {

  h1 {
    font-size: 2rem;

    & p {
      font-weight: inherit;
    }
  }
}
