// Functions

$rem-base: $font-size !default;

/// Strip Unit
/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {number} $num - Number to strip unit from.
///
/// @return The same number, sans unit.
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Convert to Rem
/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$rem-base` variable.
///
/// @param {number} $value - Pixel value to convert.
///
/// @return A number in rems, calculated based on the given value and the base pixel value.
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  @if $value == 0rem {
    $value: 0;
  } // Turn 0rem into 0
  @return $value;
}

/// Rem Calculator
/// Converts one or more pixel values into matching rem values. This function works a lot like `convert-to-rem`, except it can convert more than one value at once, which is useful when setting multiple values on a `margin` or `padding` property.
///
/// @param {number|list} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
///
/// @return A list of converted values.
@function rem-calc($values, $base-value: null) {
  @if $base-value == null {
    $base-value: $rem-base;
  }

  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $rem-values: ();

  @for $i from 1 through $max {
    $rem-values: append($rem-values, convert-to-rem(nth($values, $i), $base-value));
  }

  @return $rem-values;
}

/// Helper function to easily use an SVG inline in CSS
/// without encoding it to base64, saving bytes.
/// It also helps with browser support.
////
/// A small function allowing skipping base64 encoding
/// and simply pasting the SVG markup right in the CSS.
/// @author Jakob Eriksen
/// @link http://codepen.io/jakob-e/pen/doMoML
/// @param {String} $svg - SVG image to encode
/// @return {String} - Encoded SVG data uri
@function svg-uri($svg) {

  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk, '"', '\27');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return url('data:image/svg+xml;charset=utf8,#{$encoded}');
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @link http://sassmeister.com/gist/1b4f2da5527830088e4d
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {

  $index: str-index($string, $search);

  @if $index {

    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
