@import '../../../../layout/styles/helpers/helpers';

.prompt-answer-list {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  margin: $global-padding-small auto $global-padding;

  &__headings {
    display: flex;
    justify-content: space-between;
    text-align: initial;
  }

  &__heading {
    flex: 1 1 auto;
    max-width: 240px;
    margin-bottom: $global-padding-smaller;
    font-weight: $bold;
    font-size: rem-calc(14);

    &:first-of-type {
      padding-left: $global-padding-small;
    }

    &:last-of-type {
      padding-right: $global-padding-small;
    }
  }

  &__item {
    margin-bottom: $global-padding-small;
    text-align: initial;
  }
}
